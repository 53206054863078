
<form [formGroup]="searchVisitorsForm">

<section class="sec-1 gn-bg-1">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <div class="row ">
            <div class="col-lg-3 col-md-6 col-sm-6 col-12">
              <div class="P2-hrow-col1">
                <p class="gn-txt">From:</p>
                <input type="date" class="text-box gn-txt" formControlName="name">
              </div>
            </div>
<div class="col-lg-3 col-md-6 col-sm-6 col-12">
              <div class="P2-hrow-col1">
                <p class="gn-txt">To:</p>
                <input type="date" class="text-box gn-txt" formControlName="phone">
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 col-12">
              <button class="btn btn-primary btn-dsn" (click)="search2(this.searchVisitorsForm.controls);" >Search</button>&nbsp;
              <button class="btn btn-primary btn-dsn" (click)="clear()">Clear</button>&nbsp;
              <button class="btn btn-primary btn-dsn" (click)="printPage()">Print</button>&nbsp;
              <button class="btn btn-primary btn-dsn" (click)="exportToExcel()">Export</button>&nbsp;
              <button class="btn btn-primary btn-dsn">Close</button>
            </div>
        </div>
      </div>
    </div>
  </div>
</section>

</form>
<section class="sec-visitor gn-bg-2" id="section-to-print">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <div class="row">
          <div class="col-lg-9 col-md-9 col-sm-12 col-12 mx-auto">
            <div class="Svist-tb">
              <table class="table table-bordered">
                  <thead>
                    <tr>
                        <td>Sl.No</td>
                        <td>Customer</td>
                        <td>Phone</td>
                        <td>Model</td>
                        <td>Reg No</td>
                        <td>Last Visit</td>
                        <!-- <td>Next Visit</td> -->


                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of tableData; let i = index">
                        <td>{{i+1}}</td>
                        <td>{{item.customer_name}}</td>
                        <td>{{item.phone_no}}</td>
                        <td>{{item.model}}</td>
                        <td>{{item.reg_no}}</td>
                        <td>{{item.date | date:"dd-MM-yyyy"}}</td>
                        <!-- <td>{{item.netVisitTime | date:"dd-MM-yyyy"}}</td> -->
                    </tr>

                  </tbody>
              </table>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</section>
