<section class="sec-tyre gn-bg-1">
  <div class="container">

    <div class="col-md-12 col-12 container align-items-center justify-content-center">

      <div class="card text-center">
        <div class="card-header">
          Note
        </div>
        <div class="card-body">
          <h5 class="card-title">{{tableData.title}}</h5>
          <p class="card-text">{{tableData.note}}</p>
          <a routerLink="/admin/notes/notes-lists" class="btn btn-primary">Go Back</a>
        </div>
        <div class="card-footer text-muted">
          Expiry Date: {{tableData.date }}
        </div>
      </div>


    </div>


  </div>
</section>
