
<section class="planet-section">
	<section class="gn-bg-3 pt-nav-header">
		<nav class="navbar navbar-expand-lg">
		  <div class="container-fluid">
		    <a class="navbar-brand" routerLink="/admin/home">Planet Tyres</a>
		    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
		      <span class="navbar-toggler-icon"></span>
		    </button>
		    <div class="collapse navbar-collapse" id="navbarNav">
		      <ul class="navbar-nav ms-auto nav-cell">
		        <li class="nav-item">
		          <a class="nav-link" aria-current="page" routerLink="/admin/home">Home</a>
		        </li>
		        <li class="nav-item">
		          <a class="nav-link" routerLink="/admin/visitors">Visitors</a>
		        </li>
		        <li class="nav-item">
		          <a class="nav-link" routerLink="/admin/add-tyres">Add Tyre Details</a>
		        </li>
            <li class="nav-item">
		          <a class="nav-link" routerLink="/admin/services">Add Services</a>
		        </li>
		        <li class="nav-item">
		          <a class="nav-link" href="#">Greetings</a>
		        </li>
		        <li class="nav-item">
		          <a class="nav-link" routerLink="/admin/excel">Excel</a>
		        </li>

		        <li class="nav-item">
		          <a class="nav-link" href="#">Print</a>
		        </li>
				<li class="nav-item">
					<a class="nav-link" (click)="logout()">Loout</a>
				  </li>
		      </ul>
		    </div>
		  </div>
		</nav>
	</section>

	<section  class="remind-tab gn-bg-2">
		<div class="container-fluid">
			<div class="row align-items-center">
				<div class="col-md-6 col-lg-6 mx-auto">
					<div class="reminder-1">
						<button class="btns"><img src="../../../assets/images/credit1.png" class="img-fluid"></button>
						<button class="btns"><img src="../../../assets/images/wheel1.png" class="img-fluid"></button>
						<button class="btns" routerLink="/admin/notes/notes-lists"><img src="../../../assets/images/reminder1.png" class="img-fluid"></button>
					</div>
				</div>
			</div>
		</div>
	</section>
	<div class="reminder-desk">

		<div class="reminder">
			<button class="btns" routerLink="/admin/payments"><img src="../../../assets/images/credit1.png" class="img-fluid"></button>
			<button class="btns" routerLink="/admin/upcoming"><img src="../../../assets/images/wheel1.png" class="img-fluid"></button>
			<button class="btns" routerLink="/admin/notes/notes-lists" ><img src="../../../assets/images/reminder1.png" class="img-fluid"></button>
		</div>
	</div>
	<router-outlet></router-outlet>
</section>
<!-- <script src="js\bootstrap.bundle.min.js"></script> -->
