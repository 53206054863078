import { error } from '@angular/compiler/src/util';
import { FirebaseService } from './../../services/firebase.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.css']
})
export class NotesComponent implements OnInit {

  notesForm: FormGroup;
  tableData: any[] = [];
  constructor(public fb: FormBuilder, public db: FirebaseService) {
  }

  ngOnInit(): void {
    this.notesForm = this.fb.group({
      title: ['', Validators.required],
      date: ['', Validators.required],
      note: ['', Validators.required]
    });
    this.db.getNotes().valueChanges().subscribe(res=>{
      this.tableData = res;
    });
  }

  formSubmit() {
    if (this.notesForm.valid) {
      let convertedDate = new Date(this.notesForm.value.date + "T23:59:59").getTime();
      const title = this.notesForm.value.title;
      const date = this.notesForm.value.date;
      const note = this.notesForm.value.note;
      const dateTime = convertedDate;
      const data = {
        title: title,
        date: date,
        note: note,
        dateTime: dateTime,
      }
      this.db.addNotes(data).then(res => alert("Note Added Successfully"), error => alert("please try another time"));


    } else {
      alert("Please Fill All data");
    }
  }

  deleteNote(id:string){
    this.db.deleteNote(id).then(res => alert("Note Deleted Successfully"), error => alert("please try another time"));
  }

}
