
<h2 mat-dialog-title>Tyres Details</h2>
<mat-dialog-content class="mat-typography">
  <section>
    <table border="1" class="table table-bordered">
      <thead>
        <tr>
          <th>No</th>
          <th>Brand</th>
          <th>Pattern</th>
          <th>TT / TL</th>
          <th>Size</th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let item of tableData; let i  = index" (click)="returnDatas(item)">
          <td>{{i+1}}</td>
          <td>{{item.brand}}</td>
          <td>{{item.pattern}}</td>
          <td>{{item.ttortl}}</td>
          <td>{{item.tyresize}}</td>
        </tr>
      </tbody>

    </table>

  </section>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>

