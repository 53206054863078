import { FirebaseService } from './../../services/firebase.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.css']
})
export class PaymentsComponent implements OnInit {
  public paymentForm: FormGroup;
  tableData: any[] = [];
  constructor(public fb:FormBuilder, public db:FirebaseService) {
    this.paymentForm = this.fb.group({
      fromDate: [''],
      toDate:['']
    });
   }

  ngOnInit(): void {
    this.loadData();
  }

  search2(data:any){
    // console.log
    if(data.fromDate.value!='' && data.toDate.value!=''){
      const fromDate  = new Date(data.fromDate.value+"T00:00:01").getTime();
      const toDate  = new Date(data.toDate.value+"T23:59:59").getTime();
      this.db.filterFromandTo(fromDate,toDate).valueChanges().subscribe(res=>this.tableData = res);
    }else if(data.fromDate.value!='' && data.toDate.value==''){
      const fromDate  = new Date(data.fromDate.value+"T00:00:01").getTime();
      this.db.filterFrom(fromDate).valueChanges().subscribe(res=>this.tableData = res);
    }else if(data.fromDate.value=='' && data.toDate.value!=''){
      const toDate  = new Date(data.toDate.value+"T23:59:59").getTime();
      this.db.filterTo(toDate).valueChanges().subscribe(res=>this.tableData = res);
    }else{
      this.loadData();
    }

  }

  loadData(){
    this.db.getAllVisitors().valueChanges().subscribe((data:any) => {
      this.tableData = data

    });
  }

}
