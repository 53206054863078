<section class="sec-tyre gn-bg-1">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="row">	
                    <div class="col-lg-5 col-md-5 col-sm-12 col-12 mx-auto">
                        <form [formGroup]="tyreForm">
                        <div class="P3-hrow">
                            <div class="P3-hrow-col1">
                                <p class="gn-txt">Tyre Size:</p>
                                <input type="text"  class="text-box3 gn-txt" formControlName="tyresize" placeholder="Tyre Size" [ngClass]="{'invalid': submitForm && f.tyresize.errors}">
                            </div>
                            <div class="P3-hrow-col1">
                                <p class="gn-txt">Brand:</p>
                                <input type="text"  class="text-box3 gn-txt" formControlName="brand" placeholder="Brand" [ngClass]="{'invalid': submitForm && f.brand.errors}">
                            </div>
                            <div class="P3-hrow-col1">
                                <p class="gn-txt">Pattern:</p>
                                <input type="text"  class="text-box3 gn-txt" formControlName="pattern" placeholder="Pattern" [ngClass]="{'invalid': submitForm && f.pattern.errors}">
                            </div>
                            <div class="P3-hrow-col1">
                                <p class="gn-txt">TT/TL:</p>
                                <select formControlName="ttortl" > 
                                    <option value="TT">TT</option>
                                      <option value="TL">TL</option>
                                  </select> 
                            </div>
                            <div class="P3-hrow-col1 justify-content-center">
                                <button class="btn btn-primary" (click)="submitForm()">ADD</button>
                            </div>
                        </div>
                        </form>
                    </div>
                    <div class="col-lg-6 col-md-5 col-sm-12 col-12 mx-auto">
                        <div class="Tdetails-tb">
                            <table class="table table-bordered">
                                  <thead>
                                    <tr>
                                          <td>Sl.No</td>
                                          <td>Tyre size</td>
                                          <td>Brand</td>
                                          <td>Pattern</td>
                                          <td>TT/TL</td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let item of tableData; let i = index">
                                          <td>{{i+1}}</td>
                                          <td>{{item.tyresize}}</td>
                                          <td>{{item.brand}}</td>
                                          <td>{{item.pattern}}</td>
                                          <td>{{item.ttortl}}</td>
                                    </tr>
                                  </tbody>
                                  
                                  
                            </table>
                        </div>
                    </div>
                    <div class="col-lg-1 col-md-1 ">
                        <div class="reminder">
                            <button class="btns"><img src="../../../assets/images/credit1.png" class="img-fluid"></button>
                            <button class="btns"><img src="../../../assets/images/wheel1.png" class="img-fluid"></button>
                            <button class="btns"><img src="../../../assets/images/reminder1.png" class="img-fluid"></button>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</section>