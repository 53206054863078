import { ActivatedRoute } from '@angular/router';
import { FirebaseService } from './../../services/firebase.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-view',
  templateUrl: './home-view.component.html',
  styleUrls: ['./home-view.component.css']
})
export class HomeViewComponent implements OnInit {
  public id: any;
  public tableData: any;
  constructor(private db:FirebaseService,private route:ActivatedRoute,) {
    this.id = this.route.snapshot.params.id;
    this.db.getTheHomeFromId(this.id).valueChanges().subscribe(data=>this.tableData = data);
  }

  ngOnInit(): void {
  }

}
