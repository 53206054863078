import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(public auth:AngularFireAuth) {

   }

  checkUserLogedIn(){
    const token = localStorage.getItem('token');
    const expiryDate = localStorage.getItem('expirydate');
    if(token!=null && token!=undefined){

    }
    var date = new Date();

// add a day
date.setDate(date.getDate() + 1);
const newDate = new Date(Date.now()+1*24*60*60*1000);
    console.log("Token:", newDate.toString());
    if(Number(expiryDate) > Date.now()){

    }
    
    // get token from local storage
     
  }

   login(username:string, password:string):Promise<any> {
    return this.auth.signInWithEmailAndPassword(username,password);
  }

  userCheck(){
    console.log(this.checkUserExist());
    return !this.checkUserExist();
  }
  checkUserExist(){
    return new Promise((resolve, reject) => {
      this.auth.authState.subscribe((user) => {
        if (user) {
          resolve(true);
        }else{
          resolve(false);
         
        }
      },err=>reject(false));
    });
  }
}
