import { ServicesComponent } from '../popups/services/services.component';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { FirebaseService } from 'src/app/services/firebase.service';
import { SearchRegPopupComponent } from '../search-reg-popup/search-reg-popup.component';
import { WheelsComponent } from '../popups/wheels/wheels.component';
import { TyresComponent } from '../popups/tyres/tyres.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  TyreForm!:FormGroup;
  tableDataFormArray!: FormGroup;
  serviceTotalTotalAMount: number;
  itemTotal: number;
  public tableData: any[] = [];
  formSubmited: boolean = false;
  services: any[] = [];
  Tyres: any[] = [];
  id: any;
  customerData: any;
  cardNo: any;
  TyresArray: any[] = [];

  fileNameDialogRef: MatDialogRef<SearchRegPopupComponent>;
  serviceDialogRef: MatDialogRef<WheelsComponent>;
  tyreNameDialogRef: MatDialogRef<TyresComponent>;

  constructor( private formBuilder: FormBuilder,private db:FirebaseService,private route:ActivatedRoute, public dialog: MatDialog) {
    this.id = this.route.snapshot.params.id;
    this.cardNo = this.makeid(5);
    // if(this.id){
    //   // this.patchData(this.id);
    // }


   }

  ngOnInit(): void {
    this.initilizeForm();
    this.getAllTyres();
    this.tableDataVehicleServicesFormArrayFunction().push(this.createVehicleServicesFormArray());
    this.tableDataTyreFormArrayFunction().push(this.createTyreFormArray());
    console.log(this.makeid(5));


  }

  initilizeForm(){
    this.TyreForm = this.formBuilder.group({
      job_card_no:['',Validators.required],
      date: ['',Validators.required],
      time:['',Validators.required],
      appontment_date:['',Validators.required],
      appontment_time:['',Validators.required],
      delivery_date:['',Validators.required],
      delivery_time:['',Validators.required],
      reg_no:['',Validators.required],
      model:['',Validators.required],
      kms:['',Validators.required],
      next_visit_time:['',Validators.required],
      next_visit_date:['',Validators.required],
      customer_name:['',Validators.required],
      phone_no:['',Validators.required],
      remarks:['',Validators.required],
      pending_amount:['',Validators.required],
      paymentMode:['',Validators.required],
      serviceTotalTotalAMount:['',Validators.required],
      itemTotal:['',Validators.required],
      commenents: ['', Validators.required],
      vehicleServices: this.formBuilder.array([]),
      TyreArray: this.formBuilder.array([]),
  })
}
get f() { return this.TyreForm.controls; }

  // patchData(id:any){
  //   this.db.getCustomerDetails(id).valueChanges().subscribe((res:any)=>{
  //     this.customerData = res;
  //     this.TyreForm.controls.job_card_no.patchValue(res[0].job_card_no);
  //     this.TyreForm.controls.appontment_date.patchValue(res[0].appontment_date)
  //     this.TyreForm.controls.appontment_time.patchValue(res[0].appontment_time)
  //     this.TyreForm.controls.customer_name.patchValue(res[0].customer_name)
  //     this.TyreForm.controls.delivery_date.patchValue(res[0].delivery_date)
  //     this.TyreForm.controls.delivery_time.patchValue(res[0].delivery_time)
  //     this.TyreForm.controls.kms.patchValue(res[0].kms)
  //     this.TyreForm.controls.model.patchValue(res[0].model)
  //     this.TyreForm.controls.next_visit_date.patchValue(res[0].next_visit_date)
  //     this.TyreForm.controls.next_visit_time.patchValue(res[0].next_visit_time)
  //     this.TyreForm.controls.pending_amount.patchValue(res[0].pending_amount)
  //     this.TyreForm.controls.phone_no.patchValue(res[0].phone_no)
  //     this.TyreForm.controls.reg_no.patchValue(res[0].reg_no)
  //     this.TyreForm.controls.remarks.patchValue(res[0].remarks)
  //     this.TyreForm.controls.serviceTotalTotalAMount.patchValue(res[0].serviceTotalTotalAMount)
  //     this.TyreForm.controls.itemTotal.patchValue(res[0].itemTotal)
  //     this.TyreForm.controls.paymentMode.patchValue(res[0].paymentMode)


  //     res[0].services.forEach((element:any)=>{

  //       this.tableDataVehicleServicesFormArrayFunction().controls.forEach((e1:any)=>{
  //         e1.disable()
  //         e1.patchValue({
  //           sevice:element.sevice,
  //           amount:element.amount,
  //           discount:element.discount,
  //           quantity:element.quantity,
  //           rate:element.rate
  //         })
  //       })
  //     })
  //     res[0].Tyres.forEach((tyre:any)=>{
  //       this.tableDataTyreFormArrayFunction().controls.forEach((tyre1:any)=>{
  //         tyre1.disable();
  //         tyre1.patchValue({
  //           Qty:tyre.Qty,
  //           Amount:tyre.Amount,
  //           Discount:tyre.Discount,
  //           Rate:tyre.Rate,
  //           brand:tyre.brand,
  //           pattern:tyre.pattern,
  //           tt_tl:tyre.tt_tl,
  //           tyre_size:{brand:tyre.tyre_size.brand,
  //             date:tyre.tyre_size.date,
  //             id:tyre.tyre_size.id,
  //             pattern:tyre.tyre_size.pattern,
  //             ttortl:tyre.tyre_size.ttortl,
  //             tyresize:tyre.tyre_size.tyresize}
  //         })
  //       })
  //     })
  //     this.TyreForm.controls.job_card_no.disable();
  //     this.TyreForm.controls.appontment_date.disable();
  //     this.TyreForm.controls.appontment_time.disable();
  //     this.TyreForm.controls.customer_name.disable();
  //     this.TyreForm.controls.delivery_date.disable();
  //     this.TyreForm.controls.delivery_time.disable();
  //     this.TyreForm.controls.kms.disable();
  //     this.TyreForm.controls.model.disable();
  //     this.TyreForm.controls.next_visit_date.disable();
  //     this.TyreForm.controls.next_visit_time.disable();
  //     this.TyreForm.controls.pending_amount.disable();
  //     this.TyreForm.controls.phone_no.disable();
  //     this.TyreForm.controls.reg_no.disable();
  //     this.TyreForm.controls.remarks.disable();
  //     this.TyreForm.controls.itemTotal.disable();
  //     this.TyreForm.controls.serviceTotalTotalAMount.disable();
  //     this.TyreForm.controls.paymentMode.disable();

  //   })

  // }


getAllTyres(){
  this.db.getAllTyres().valueChanges().subscribe((data:any) => {
    this.tableData = data
  });
}

onSelectTyreSize(tyresize:any,i:any){
  this.tableDataTyreFormArrayFunction().controls[i].patchValue({
    brand:tyresize.brand,
    pattern:tyresize.pattern,
    tt_tl:tyresize.ttortl,
  })

}

// services formarray
private createVehicleServicesFormArray(): FormGroup {
  return (this.tableDataFormArray = this.formBuilder.group({
    sevice:  new FormControl('',Validators.required),
    quantity:  new FormControl('',Validators.required),
    rate:  new FormControl('',Validators.required),
    amount:  new FormControl('',Validators.required),
    discount:  new FormControl(''),
  }));
}

public addVehicleServices() {
  this.tableDataVehicleServicesFormArrayFunction().push(this.createVehicleServicesFormArray());
}

tableDataVehicleServicesFormArrayFunction(): FormArray {
  return this.TyreForm.get('vehicleServices') as FormArray;
}

deleteVehicleServices(amount:any,index:number) {
  if(this.tableDataVehicleServicesFormArrayFunction().length > 1 ){
  const control = <FormArray>this.TyreForm.controls['vehicleServices'];
  control.removeAt(index);
  this.serviceTotalTotalAMount = this.serviceTotalTotalAMount - amount;
}
}
// services formarray end

// tyre formarray
private createTyreFormArray(): FormGroup {
  return (this.tableDataFormArray = this.formBuilder.group({
    tyre_size: new FormControl('',Validators.required),
    brand:  new FormControl('',Validators.required),
    pattern:  new FormControl('',Validators.required),
    tt_tl:  new FormControl('',Validators.required),
    Qty:  new FormControl('',Validators.required),
    Rate:  new FormControl('',Validators.required),
    Amount:  new FormControl('',Validators.required),
    Discount:  new FormControl(''),
    tyre_size_id:  new FormControl('',)
  }));
}

public addTyreFormGroup() {
  this.tableDataTyreFormArrayFunction().push(this.createTyreFormArray());
}

tableDataTyreFormArrayFunction(): FormArray {
  return this.TyreForm.get('TyreArray') as FormArray;
}

deleteTyreServices(amount:any,index:number) {
  if(this.tableDataTyreFormArrayFunction().length > 1 ){
  const control = <FormArray>this.TyreForm.controls['TyreArray'];
  control.removeAt(index);
  this.itemTotal = this.itemTotal - amount;

}
}
// tyre formarray end


onInputRate(qty:number, rate:any,i:any){
  this.serviceTotalTotalAMount = 0;
  this.tableDataVehicleServicesFormArrayFunction().controls[i].patchValue({
    amount:Number(qty) * rate
  })
  setTimeout(() => {
    this.tableDataVehicleServicesFormArrayFunction().controls.forEach((el:any)=>{
      this.serviceTotalTotalAMount = this.serviceTotalTotalAMount += +el.value.amount;
    })
  }, );
}

onInputTyreRate(qty:number, rate:any,i:any){
  this.itemTotal = 0;
  this.tableDataTyreFormArrayFunction().controls[i].patchValue({
    Amount:Number(qty) * rate
  })
  setTimeout(() => {
    this.tableDataTyreFormArrayFunction().controls.forEach((el:any)=>{
      this.itemTotal = this.itemTotal += +el.value.Amount;
    })
  }, );
}

submitForm() {
  this.Tyres = [];
  this.formSubmited = false;

  console.log(this.TyreForm.value.appontment_date);
  //const demo = ;
  // if (this.TyreForm.invalid) {
    // const appoinmentTime = new Date(this.TyreForm.value.appontment_date+"T"+this.TyreForm.value.appontment_time+":00").getTime();
//console.log(demo);
  //  console.log("appoinmentTime: ",appoinmentTime);
  // } else {
    this.formSubmited = true;
    const job_card_no = this.TyreForm.value.job_card_no;
    const appontment_date = this.TyreForm.value.appontment_date;
    const appontment_time = this.TyreForm.value.appontment_time;
    const delivery_date = this.TyreForm.value.delivery_date;
    const delivery_time = this.TyreForm.value.delivery_time;
    const reg_no = this.TyreForm.value.reg_no;
    const model = this.TyreForm.value.model;
    const kms = this.TyreForm.value.kms;
    const next_visit_date = this.TyreForm.value.next_visit_date;
    const next_visit_time = this.TyreForm.value.next_visit_time;
     const customer_name = this.TyreForm.value.customer_name;
    const phone_no = this.TyreForm.value.phone_no;
    const remarks = this.TyreForm.value.remarks;
    const pending_amount = this.TyreForm.value.pending_amount;
    const paymentMode = this.TyreForm.value.paymentMode;
    const date = this.TyreForm.value.date;
    const time = this.TyreForm.value.time;
    const comments = this.TyreForm.value.commenents;

    this.tableDataVehicleServicesFormArrayFunction().controls.forEach((el:any)=>{
      this.services.push({
        sevice:el.value.sevice,
        quantity:el.value.quantity,
        rate:el.value.rate,
        amount:el.value.amount,
        discount:el.value.discount,
      })
    })
    const appoinmentTime = new Date(this.TyreForm.value.appontment_date+"T"+this.TyreForm.value.appontment_time+":00").getTime();
    const deliveryTime = new Date(this.TyreForm.value.delivery_date+"T"+this.TyreForm.value.delivery_time+":00").getTime();
    const createdTime = new Date(this.TyreForm.value.date+"T"+this.TyreForm.value.time+":00").getTime();
    const netVisitTime = new Date(this.TyreForm.value.next_visit_date+"T00:00:01").getTime();
    // const nextVisitTime = new Date(this.TyreForm.value.next_visit_date+"T"+this.TyreForm.value.next_visit_time+":00").getTime();
    this.tableDataTyreFormArrayFunction().controls.forEach((tyre:any)=>{
      this.TyresArray.push({
        tyre_size:tyre.value.tyre_size,
        tyre_size_id:tyre.value.tyre_size,
        brand:tyre.value.brand,
        pattern:tyre.value.pattern,
        tt_tl:tyre.value.tt_tl,
        Qty:tyre.value.Qty,
        Rate:tyre.value.Rate,
        Amount:tyre.value.Amount,
        Discount:tyre.value.Discount,
      })
    })

    const data = {
      job_card_no: job_card_no,
      appontment_date: appontment_date,
      appontment_time: appontment_time,
      delivery_date: delivery_date,
      delivery_time:delivery_time,
      reg_no: reg_no,
      model: model,
      kms: kms,
      next_visit_date: next_visit_date,
      next_visit_time: next_visit_time,
      customer_name: customer_name,
      phone_no: phone_no,
      remarks: remarks,
      pending_amount: pending_amount,
      services:this.services,
      Tyres:this.TyresArray,
      itemTotal:this.itemTotal,
      appoinmentTime: appoinmentTime,
      deliveryTime: deliveryTime,
      serviceTotalTotalAMount:this.serviceTotalTotalAMount,
      paymentMode:paymentMode,
      createdTime:createdTime,
      date: date,
      time: time,
      comments: comments,
      netVisitTime:netVisitTime
    }
    console.log(data);
    this.db.addServiceDetails(data).then(res => {
      alert('Data saved successfully');
      this.TyreForm.reset();this.itemTotal = 0;
      this.serviceTotalTotalAMount = 0;
    });
}


popupRegFromUsers(){
  const regno  = this.TyreForm.value.reg_no;
   this.fileNameDialogRef =  this.dialog.open(SearchRegPopupComponent, {
    data:{
      "carno": regno
    }
   });

   this.fileNameDialogRef.afterClosed().subscribe(result=>{
    this.TyreForm.controls.phone_no.patchValue(result.phone_no);
      this.TyreForm.controls.customer_name.patchValue(result.customer_name);
      this.TyreForm.controls.pending_amount.patchValue(result.pending_amount);
      this.TyreForm.controls.remarks.patchValue(result.remarks);
      this.TyreForm.controls.model.patchValue(result.model);
   });
}

popupTyreFromUsers(i){
  const regno  = this.TyreForm.value.reg_no;
   this.tyreNameDialogRef =  this.dialog.open(TyresComponent, {
    data:{
      "index": i
    }
   });

   this.tyreNameDialogRef.afterClosed().subscribe(result=>{
    // console.log(result);
    (this.tableDataTyreFormArrayFunction().at(result.index)).get('brand').patchValue(result.brand);
    (this.tableDataTyreFormArrayFunction().at(result.index)).get('tyre_size').patchValue(result.tyresize);
    (this.tableDataTyreFormArrayFunction().at(result.index)).get('pattern').patchValue(result.pattern);
    (this.tableDataTyreFormArrayFunction().at(result.index)).get('tt_tl').patchValue(result.ttortl);
    // (this.tableDataTyreFormArrayFunction().at(result.index)).get('brand').patchValue(result.brand);
    // this.TyreForm.controls.phone_no.patchValue(result.phone_no);
    //   this.TyreForm.controls.customer_name.patchValue(result.customer_name);
    //   this.TyreForm.controls.pending_amount.patchValue(result.pending_amount);
    //   this.TyreForm.controls.remarks.patchValue(result.remarks);
    //   this.TyreForm.controls.model.patchValue(result.model);
   });
}




//  convertTo24HourFormat(time12hr: string): string {
//   const [timePart, meridiem] = time12hr.split(' ');
//   const [hours, minutes, seconds] = timePart.split(':').map(Number);

//   if (meridiem === 'AM') {
//       if (hours === 12) {
//           return `${String(0).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:00`;
//       } else {
//           return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:00}`;
//       }
//   } else if (meridiem === 'PM') {
//       if (hours === 12) {
//           return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:00`;
//       } else {
//           return `${String(hours + 12).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:00`;
//       }
//   } else {
//       throw new Error('Invalid meridiem value. Use "AM" or "PM".');
//   }
// }

// Example usage
// Output: "19:30:45"


// Example usage
 // Output: "19:05:45"
 selectServices(i: any){
  this.serviceDialogRef =  this.dialog.open(WheelsComponent, {
    data:{
      "index": i
    }
   });

   this.serviceDialogRef.afterClosed().subscribe(result=>{
    console.log(result);
    (this.tableDataVehicleServicesFormArrayFunction().at(result.index)).get('sevice').patchValue(result.name);
   });
 }

  makeid(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}



}
