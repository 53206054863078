import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FirebaseService } from 'src/app/services/firebase.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-excel',
  templateUrl: './excel.component.html',
  styleUrls: ['./excel.component.css']
})
export class ExcelComponent implements OnInit {
  ExcelForm!:FormGroup
  formSubmited: boolean;
  tableData: any[][];
  excelTableData: any = [];
  editId: any;
  constructor( private formBuilder: FormBuilder,private db:FirebaseService,private router:Router) { }

  ngOnInit(): void {
    this.initilizeForms();
    this.loadData();
  }

  initilizeForms(){
    this.ExcelForm = this.formBuilder.group({
      jobcard_no:[''],
      reg_no:['',Validators.required],
      amount:['',Validators.required],
      expense:['',Validators.required],
      credit:['',Validators.required],
      payment_mode:['',Validators.required],
    })
  }

  loadData(){
    this.db.getAllExcelData().valueChanges().subscribe(data => this.tableData = data);

  }

  download(){
    this.excelTableData = [];
    this.tableData.forEach((data:any) => {
      let x = {
        'jobCard No ':data.jobcard_no ,
        'reg_no':data.reg_no ,
        'amount':data.amount ,
        'expense':data.expense ,
        'credit':data.credit ,
        'payment_mode':data.payment_mode ,
      };
      this.excelTableData.push(x);

    });
    this.downloadExcel(this.excelTableData,'customer List');
  }

  savetoExcel(){
    this.formSubmited = false;
    if (this.ExcelForm.invalid) {

    } else {
      this.formSubmited = true;
      const jobcard_no = this.ExcelForm.value.jobcard_no;
      const reg_no = this.ExcelForm.value.reg_no;
      const amount = this.ExcelForm.value.amount;
      const expense = this.ExcelForm.value.expense;
      const credit = this.ExcelForm.value.credit;
      const payment_mode = this.ExcelForm.value.payment_mode;
      const data = {
        jobcard_no: jobcard_no,
        reg_no: reg_no,
        amount: amount,
        expense: expense,
        credit:credit,
        payment_mode:payment_mode
      }
      this.db.addtoExcel(data).then(res => {
        alert('Data Added Sccessfully');
        this.ExcelForm.reset();
        this.loadData();
      });
    }
  }

  delete(id:any){
    this.db.deleteData(id).then(res => {
      alert('Data Deleted Sccessfully');
      this.ExcelForm.reset();
      this.loadData();

    })
  }
  edit(id:any){
    this.editId = id;
    this.db.getExcelDataById(id).valueChanges().subscribe((res:any)=>{
      this.ExcelForm.controls.amount.patchValue(res[0].amount)
      this.ExcelForm.controls.jobcard_no.patchValue(res[0].jobcard_no)
      this.ExcelForm.controls.reg_no.patchValue(res[0].reg_no)
      this.ExcelForm.controls.expense.patchValue(res[0].expense)
      this.ExcelForm.controls.credit.patchValue(res[0].credit)
      this.ExcelForm.controls.payment_mode.patchValue(res[0].payment_mode)
      this.formSubmited = false;


    })
    // this.router.navigateByUrl('/admin/home/'+id)
  }

  update(){
    if (this.ExcelForm.invalid) {

    } else {
      this.formSubmited = true;
      const jobcard_no = this.ExcelForm.value.jobcard_no;
      const reg_no = this.ExcelForm.value.reg_no;
      const amount = this.ExcelForm.value.amount;
      const expense = this.ExcelForm.value.expense;
      const credit = this.ExcelForm.value.credit;
      const payment_mode = this.ExcelForm.value.payment_mode;
      const data = {
        jobcard_no: jobcard_no,
        reg_no: reg_no,
        amount: amount,
        expense: expense,
        credit:credit,
        payment_mode:payment_mode
      }
      this.db.updateExcel(this.editId,data).then(res => {
        alert('Data updated Sccessfully');
        this.ExcelForm.reset();
        this.loadData();
      });
    }
  }

  downloadExcel(downloadData: any, fileName: any) {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(downloadData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.sheet_add_json(ws, downloadData, {
      origin: 'A2',
      skipHeader: true,
    });
    XLSX.utils.book_append_sheet(wb, ws, 'Export Advance List');
    XLSX.writeFile(wb, fileName + '.xlsx');
  }


  }

