<section class="sec-1 gn-bg-1">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-6 col-sm-6 col-12">
              <div class="hrow-col1">
                <p class="gn-txt">Job Card No: &nbsp;{{tableData?.job_card_no}}</p>
                <div class="input-group grp">

                        </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6 col-12">
              <div class="hrow-col1">
                <p class="gn-txt">Date: &nbsp;{{tableData?.date | date:'dd/MM/yyyyy'}}</p>

              </div>

            </div>

            <div class="col-lg-3 col-md-6 col-sm-6 col-12">
              <div class="hrow-col1">
                <p class="gn-txt">Appointment: &nbsp;{{tableData?.appoinmentTime | date :'short'}}</p>

              </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6 col-12">
              <div class="hrow-col1">
                <p class="gn-txt">Delivery: &nbsp;{{tableData?.deliveryTime | date :'short'}}</p>

              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="sec-2 gn-bg-2">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <div class="row mb-1 align-items-center">
          <div class="col-lg-3 col-md-6 col-sm-6 col-12">
            <div class="hrow-col1">
              <p class="gn-txt">Regn. No: &nbsp;{{tableData?.reg_no}}</p>
              <div class="input-group grp">

                    </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 col-sm-6 col-12">
            <div class="hrow-col1">
              <p class="gn-txt">Model/ Make: &nbsp;{{tableData?.model}}</p>

            </div>

          </div>

          <div class="col-lg-3 col-md-6 col-sm-6 col-12">
            <div class="hrow-col1">
              <p class="gn-txt">KMs: &nbsp;{{tableData?.kms}}</p>

            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 col-12">
            <div class="hrow-col1">
              <p class="gn-txt">Nxt Visit: &nbsp;{{tableData?.netVisitTime | date:'dd/MM/yyyyy'}} KMs: &nbsp;{{tableData?.next_visit_time}}</p>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="sec-3 gn-bg-1">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <div class="row align-items-center">
          <div class="col-lg-3 col-md-6 col-sm-6 col-12">
            <div class="hrow-col1">
              <p class="gn-txt">Customer Name: &nbsp;{{tableData?.customer_name}}</p>

            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 col-12">
            <div class="hrow-col1">
              <p class="gn-txt">Phone: &nbsp;{{tableData?.phone_no}}</p>

            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 col-12">
            <div class="hrow-col1">
              <p class="gn-txt">Remarks: &nbsp;{{tableData?.remarks}}</p>

            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 col-12">
            <div class="hrow-col1">
              <p class="gn-txt">Pending Amount: &nbsp;{{tableData?.pending_amount}}</p>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="sec-4 gn-bg-2">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <div class="row">
          <div class="col-lg-10 col-md-10 col-sm- col-12">
            <div class="Sfee-tb">
              <table class="table table-bordered ">
                  <thead>
                    <tr>
                        <td>Sl.No</td>
                        <td>Description</td>
                        <td>Quantity</td>
                        <td>Rate</td>
                        <td>Amount</td>
                        <td>Discount</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of tableData?.services; let i  = index">
                        <td>{{i+1}}</td>
                        <td>{{item.sevice}}</td>
                        <td>{{item.quantity}}</td>
                        <td>{{item.rate}}</td>
                        <td>{{item.amount}}</td>
                        <td>{{item.discount}}</td>
                    </tr>

                  </tbody>
              </table>
            </div>
            <div class="hrow-col1">
              <p class="gn-txt">Service Total: &nbsp;{{tableData?.serviceTotalTotalAMount}}</p>

            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</section>
<section class="sec-5 gn-bg-1">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <div class="row">
          <div class="col-lg-10 col-md-12 col-sm-12 col-12">
            <div class="Ifee-tb">
              <table class="table table-bordered">
                  <thead>
                    <tr>
                        <td>Sl.No</td>
                        <td>Tyre size</td>
                        <td>Brand</td>
                        <td>Pattern</td>
                        <td>TT/TL</td>
                        <td>Qty</td>
                        <td>Rate</td>
                        <td>Amount</td>
                        <td>Discount</td>

                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of tableData?.Tyres; let i  = index">
                        <td>{{i+1}}</td>
                        <td>{{item.tyre_size}}</td>
                        <td>{{item.brand}}</td>
                        <td>{{item.pattern}}</td>
                        <td>{{item.tt_tl}}</td>
                        <td>{{item.Qty}}</td>
                        <td>{{item.Rate}}</td>
                        <td>{{item.Amount}}</td>
                        <td>{{item.Discount}}</td>

                    </tr>
                  </tbody>


              </table>
            </div>

          </div>
        </div>
        <div class="row">
          <div class="col-md-4 col-12">
            <div class="hrow-col1">
              <p class="gn-txt">Tyre Total: &nbsp;{{tableData?.itemTotal}}</p>

            </div>
          </div>
          <div class="col-md-4 col-12">
            <div class="hrow-col1">
              <p class="gn-txt">Total Amount: &nbsp;{{tableData?.itemTotal + tableData?.serviceTotalTotalAMount}}</p>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="sec-7 gn-bg-2">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <div class="row align-items-center">
          <div class="col-lg-3 col-md-6 col-sm-6 col-12">
            <div class="hrow-col1">
              <p class="gn-txt">Payment mode: &nbsp;{{tableData?.paymentMode}}</p>

            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 col-12">
            <div class="hrow-col1">
              <!-- <button class="btn btn-primary btn-print">PRINT BILL</button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
