<form [formGroup]="TyreForm">

  <section class="sec-1 gn-bg-1">
		<div class="container-fluid">
			<div class="row">
				<div class="col-lg-12 col-md-12 col-sm-12 col-12">
					<div class="row align-items-center">
							<div class="col-lg-3 col-md-6 col-sm-6 col-12">
                <div class="hrow-col1">
									<p class="gn-txt">Job Card No:</p>
									<div class="input-group grp">
                          <input type="text" class="txt gn-txt" readonly [(ngModel)]="cardNo" formControlName="job_card_no" >
                      </div>
								</div>
							</div>
							<div class="col-lg-3 col-md-6 col-sm-6 col-12">
								<div class="hrow-col1">
									<p class="gn-txt">Date:</p>
									<input type="date" class="text-box1 gn-txt" formControlName="date">
									<input type="time" class="text-box1 gn-txt" formControlName="time">
								</div>

							</div>
							<div class="col-lg-3 col-md-6 col-sm-6 col-12">
								<div class="hrow-col1">
									<p class="gn-txt">Appointment</p>
									<input type="date"class="text-box1 gn-txt" formControlName="appontment_date">
									<input type="time" class="text-box1 gn-txt" formControlName="appontment_time">
								</div>

							</div>

							<div class="col-lg-3 col-md-6 col-sm-6 col-12">
								<div class="hrow-col1">
									<p class="gn-txt">Delivery:</p>
									<input type="date" class="text-box1 gn-txt" formControlName="delivery_date">
                  <input type="time" class="text-box1 gn-txt" formControlName="delivery_time">

								</div>
							</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<section class="sec-2 gn-bg-2">
		<div class="container-fluid">
			<div class="row">
				<div class="col-lg-12 col-md-12 col-sm-12 col-12">
					<div class="row mb-1">
            <div class="col-lg-3 col-md-6 col-sm-6 col-12">
              <div class="hrow-col1">
                <p class="gn-txt ">Regn. No:</p>
				<div class="input-group grp">
					<input type="text" class="txt gn-txt"  formControlName="reg_no">
					<button class="btnss"><img src="../../../assets/images/search.png" class="img-fluid" (click)="popupRegFromUsers()"></button>
				</div>

              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 col-12">
              <div class="hrow-col1">
                <p class="gn-txt">Model/ Make:</p>
                <input type="text" class="text-box gn-txt" formControlName="model">
              </div>

            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 col-12">
              <div class="hrow-col1">
                <p class="gn-txt">KMs:</p>
                <input type="text" class="text-box gn-txt" formControlName="kms">
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 col-12">
              <div class="hrow-col1">
                <p class="gn-txt">Nxt Visit & KMs:</p>
                <input type="date" class="text-box1 gn-txt"formControlName="next_visit_date" >
                <input type="text" class="text-box1 gn-txt" formControlName="next_visit_time">
              </div>
            </div>
            </div>
            </div>
            </div>
            </div>
            </section>

	<section class="sec-3 gn-bg-1">
		<div class="container-fluid">
			<div class="row">
				<div class="col-lg-12 col-md-12 col-sm-12 col-12">
					<div class="row ">
						<div class="col-lg-3 col-md-6 col-sm-6 col-12">
							<div class="hrow-col1">
								<p class="gn-txt">Customer Name:</p>
								<input type="text" class="text-box gn-txt" formControlName="customer_name">
							</div>
						</div>
						<div class="col-lg-3 col-md-6 col-sm-6 col-12">
							<div class="hrow-col1">
								<p class="gn-txt">Phone:</p>
								<input type="tel" class="text-box gn-txt" formControlName="phone_no">
							</div>
						</div>
						<div class="col-lg-3 col-md-6 col-sm-6 col-12">
							<div class="hrow-col1">
								<p class="gn-txt">Remarks:</p>
								<input type="text" class="text-box gn-txt" formControlName="remarks">
							</div>
						</div>
						<div class="col-lg-3 col-md-6 col-sm-6 col-12">
							<div class="hrow-col1">
								<p class="gn-txt">Pending Amount:</p>
								<input type="text" class="text-box gn-txt"formControlName="pending_amount">
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<section class="sec-4 gn-bg-2">
		<div class="container-fluid">
			<div class="row">
				<div class="col-lg-12 col-md-12 col-sm-12 col-12">
					<div class="row">
            <ng-container formArrayName="vehicleServices">

						<div class="col-lg-10 col-md-9 col-sm-8 col-12">
							<div class="Sfee-tb">
								<table class="table table-bordered ">
  									<thead>
    									<tr>
      										<td>Sl.No</td>
      										<td>Description</td>
      										<td>Quantity</td>
      						    			<td>Rate</td>
      										<td>Amount</td>
      										<td>Discount</td>
                          <td *ngIf="!this.id"><span  class="btn-add" (click)="addVehicleServices()">+</span></td>
    									</tr>
  									</thead>
  									<tbody>
    									<tr *ngFor=" let item of tableDataVehicleServicesFormArrayFunction().controls;  let i = index " [formGroupName]="i">
      										<td>{{i+1}}</td>
      										<td><input type="text" class="text-box pt-txt-box" formControlName="sevice" (click)="selectServices(i)"></td>
      										<td><input type="text" class="text-box pt-txt-box"formControlName="quantity"></td>
      										<td><input type="text" class="text-box pt-txt-box"formControlName="rate" (input)="onInputRate(tableDataVehicleServicesFormArrayFunction().controls[i].value.quantity,tableDataVehicleServicesFormArrayFunction().controls[i].value.rate,i)"></td>
      										<td><input type="text" class="text-box pt-txt-box"formControlName="amount"></td>
      										<td><input type="text" class="text-box pt-txt-box"formControlName="discount"></td>
                          <td *ngIf="!this.id">
                            <span class="btn-add" (click)="deleteVehicleServices(tableDataVehicleServicesFormArrayFunction().controls[i].value.amount,i)">-</span>
                          </td>
    									</tr>
  									</tbody>
								</table>

							</div>

						</div>
          </ng-container>
          <div class="hrow-col1">
            <p class="gn-txt">Service Total:</p>
            <input type="text" class="text-box gn-txt" formControlName="serviceTotalTotalAMount" [(ngModel)]="serviceTotalTotalAMount" value="{{serviceTotalTotalAMount}}">
          </div>

					</div>

				</div>
			</div>
		</div>
	</section>
	<section class="sec-5 gn-bg-1">
		<div class="container-fluid">
			<div class="row">
				<div class="col-lg-12 col-md-12 col-sm-12 col-12">
					<div class="row">
						<div class="col-lg-11 col-md-12 col-sm-12 col-12">
							<div class="Sfee-tb">
                <ng-container formArrayName="TyreArray">
								<table class="table table-bordered">
  									<thead>
    									<tr>
      										<td>Sl.No</td>
      										<td>Tyre size</td>
      										<td>Brand</td>
      										<td>Pattern</td>
      										<td>TT/TL</td>
      										<td>Qty</td>
      										<td>Rate</td>
      										<td>Amount</td>
      										<td>Discount</td>
                          <td style="width: 20%;" *ngIf="!this.id"><span  class="btn-add" (click)="addTyreFormGroup()">+</span></td>
    									</tr>
  									</thead>
  									<tbody>
    									<tr *ngFor=" let item of tableDataTyreFormArrayFunction().controls;  let i = index " [formGroupName]="i">
                        <td>{{i+1}}</td>
                        <td> <input type="text" class="text-box pt-txt-box" formControlName="tyre_size"   (click)="popupTyreFromUsers(i)"></td>
                        <td>	{{tableDataTyreFormArrayFunction().controls[i].value.brand}}</td>
                        <td>	{{tableDataTyreFormArrayFunction().controls[i].value.pattern}}</td>
                        <td>	{{tableDataTyreFormArrayFunction().controls[i].value.tt_tl}}</td>
                        <td>	<input type="text" class="text-box pt-txt-box" formControlName="Qty"  ></td>
                        <td>	<input type="text" class="text-box pt-txt-box"formControlName="Rate" (input)="onInputTyreRate(tableDataTyreFormArrayFunction().controls[i].value.Qty,tableDataTyreFormArrayFunction().controls[i].value.Rate,i)"></td>
                        <td>	<input type="text" class="text-box pt-txt-box" formControlName="Amount" disabled ></td>
                        <td>	<input type="text" class="text-box pt-txt-box"formControlName="Discount"></td>

                        <td *ngIf="!this.id">

                          <span class="btn-add" (click)="deleteTyreServices(tableDataTyreFormArrayFunction().controls[i].value.Amount,i)">-</span>
                        </td>
                    </tr>
  									</tbody>


								</table>
                </ng-container>
							</div>
							<div class="hrow-col1">
								<p class="gn-txt">Item Total:</p>
								<input type="text" class="text-box gn-txt" formControlName="itemTotal" [(ngModel)]="itemTotal" value="{{itemTotal}}">
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<section class="sec-7 gn-bg-2">
		<div class="container-fluid">
			<div class="row">
				<div class="col-lg-12 col-md-12 col-sm-12 col-12">
					<div class="row">
						<div class="col-lg-4 col-md-6 col-sm-6 col-12">
							<div class="hrow-col1">
								<p class="gn-txt">Payment mode:</p>
								<select formControlName="paymentMode">
									<option value='SWIPE'>Swipe</option>
									<option value='GPAY'>Gpay</option>
									<option value='PHONEPE'>PhonePe</option>
								</select>
							</div>
						</div>
            <div class="col-lg-4 col-md-6 col-sm-6 col-12">
              <div class="hrow-col1">
                <p class="gn-txt">Comments: </p>
                <input type="text" class="text-box gn-txt" formControlName="commenents" >
              </div>
            </div>

						<div class="col-lg-4 col-md-6 col-sm-6 col-12" *ngIf="!this.id">
							<div class="hrow-col1">
								<button class="btn btn-primary btn-print" (click)="submitForm()" >SAVE</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</form>
