

<form [formGroup]="ExcelForm">
<section class="P4-hrow gn-bg-1">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <div class="row">
          <div class="col-lg-4 col-md-4 col-sm-12 col-12 mx-auto">
            <div class="P4-hrow-col1">
              <p class="gn-txt">Job Card No:</p>
              <input type="text"  class="text-box3 gn-txt" formControlName="jobcard_no">
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12 col-12 mx-auto">
            <div class="P4-hrow-col1">
              <p class="gn-txt">Regn. No:</p>
              <input type="text"  class="text-box3 gn-txt" formControlName="reg_no">
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12 col-12 mx-auto">
            <div class="P4-hrow-col1">
              <p class="gn-txt">Amount:</p>
              <input type="text"  class="text-box3 gn-txt" formControlName="amount">
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12 col-12 mx-auto">
            <div class="P4-hrow-col1">
              <p class="gn-txt">Expense:</p>
              <input type="text"  class="text-box3 gn-txt" formControlName="expense">
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12 col-12 mx-auto">
            <div class="P4-hrow-col1">
              <p class="gn-txt">Credit:</p>
              <input type="text"  class="text-box3 gn-txt" formControlName="credit">
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12 col-12 mx-auto">
            <div class="P4-hrow-col1">
              <p class="gn-txt">Payment Mode:</p>
              <select formControlName="payment_mode">
                <option>Swipe</option>
                <option>Gpay</option>
                <option>PhonePe</option>
              </select>
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="P4-hrow-col1 justify-content-center">
              <button class="btn btn-primary" *ngIf="!editId" (click)="savetoExcel()" [disabled]="ExcelForm.invalid">Save</button>
              <button class="btn btn-primary" *ngIf="editId"  (click)="update()" [disabled]="ExcelForm.invalid">Update</button>


              <button class="btn btn-primary" (click)="download()">Download</button>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="P4-hrow gn-bg-2">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <div class="row">
          <div class="col-lg-11 col-md-11 col-sm-12 col-12 mx-auto">
            <div class="excel-tb">
              <table class="table table-bordered">
                  <thead>
                    <tr>
                        <td>Sl.No</td>
                        <td>Job Card No</td>
                        <td>Regn. No</td>
                        <td>Amount</td>
                        <td>Expense</td>
                        <td>Credit</td>
                        <td>Payment Mode</td>
                        <td></td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of tableData; let i = index">
                        <td>{{i+1}}</td>
                        <td>{{item.jobcard_no}}</td>
                        <td>{{item.reg_no}}</td>
                        <td>{{item.amount}}</td>
                        <td>{{item.expense}}</td>
                        <td>{{item.credit}}</td>
                        <td>{{item.payment_mode}}</td>
                        <td>
                          <button class="btn btn-primary" (click)="edit(item.id)">Edit</button>
                          <button class="btn btn-primary" (click)="delete(item.id)">Delete</button>
                        </td>
                    </tr>
              </table>
            </div>
          </div>

        </div>
        <div class="row">
          <div class="col-lg-11 col-md-11 col-sm-12 col-12 justify-content-center">
            <button class="btn btn-primary btn-clear">CLEAR ALL</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
</form>
