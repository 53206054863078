import { Component, OnInit } from '@angular/core';
import { FirebaseService } from '../../services/firebase.service';
import { FormGroup } from '@angular/forms';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-add-tyres',
  templateUrl: './add-tyres.component.html',
  styleUrls: ['./add-tyres.component.css']
})
export class AddTyresComponent implements OnInit {

  public tableData: any[] = [];
  public tyreForm: FormGroup;
  formSubmited: boolean = false;
  constructor(public db: FirebaseService, public fb: FormBuilder) {
    this.tableData = [];


    this.tyreForm = this.fb.group({
      tyresize: ['', Validators.required],
      brand: ['', Validators.required],
      pattern: ['', Validators.required],
      ttortl: ['', Validators.required]
    });

  }
  get f() { return this.tyreForm.controls; }
  ngOnInit(): void {
    this.db.getAllTyres().valueChanges().subscribe(data => this.tableData = data);
  }

  submitForm() {
    this.formSubmited = false;
    if (this.tyreForm.invalid) {

    } else {
      this.formSubmited = true;
      const tyresize = this.tyreForm.value.tyresize;
      const brand = this.tyreForm.value.brand;
      const pattern = this.tyreForm.value.pattern;
      const ttortl = this.tyreForm.value.ttortl;

      const data = {
        tyresize: tyresize,
        brand: brand,
        pattern: pattern,
        ttortl: ttortl
      }
      this.db.addTyres(data).then(res => {
        alert('Tyre Added');
        this.tyreForm.reset();
      });
    }
  }

}
