<form>
  <section class="P4-hrow gn-bg-1 p-3">
      <div class="container-fluid">
          <div class="row">

              <form [formGroup]="servicesForm">

                  <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                      <div class="row">
                          <div class="col-lg-8 col-md-12 col-sm-12 col-12 mx-auto">
                              <div class="P4-hrow-col1">
                                  <p class="gn-txt">Service Name: &nbsp;</p>
                                  <input
                                      type="text"
                                      class="text-box3 gn-txt col-md-6"
                                      formControlName="serviceName"
                                  >
                              </div>
                          </div>
                          <div class="col-lg-8 col-md-12 col-sm-12 col-12 mx-auto">
                              <div class="P4-hrow-col1">
                                  <p class="gn-txt">Short Code: &nbsp;</p>
                                  <input
                                      type="text"
                                      class="text-box3 col-md-6 gn-txt"
                                      formControlName="shortCode"
                                  >
                              </div>
                          </div>




                          <div class="col-lg-8 col-md-12 col-sm-12 col-12 mx-auto">
                              <div class="P4-hrow-col1">
                                  <button
                                      class="btn btn-primary"
                                      (click)="formSubmit()"
                                  >
                                      Save Now
                                  </button>
                              </div>
                          </div>
                      </div>
                  </div>

              </form>

          </div>
      </div>
  </section>
  <section class="sec-pagetwo gn-bg-2">
      <div class="container-fluid">
          <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                  <div class="row">
                      <div class="col-lg-5 col-md-5 col-sm-12 col-12 mx-auto">
                          <div class="pagetwo-tb">
                              <table class="table table-bordered">
                                  <thead>
                                      <tr>
                                          <td>SL No</td>
                                          <td>Service Name</td>
                                          <td>Short Code</td>

                                          <td>Action</td>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      <tr *ngFor="let item of tableData; let i = index">
                                          <td>{{i+1}}</td>
                                          <td>{{item.name}}</td>
                                          <td>{{item.code}}</td>

                                          <td>
                                              <button
                                                  class="btn btn-primary"
                                                  (click)="deleteServices(item.id)"
                                              >
                                                  Delete
                                              </button>
                                          </td>
                                      </tr>
                                  </tbody>
                              </table>
                          </div>
                      </div>

                  </div>
                  <div class="row">
                      <div class="col-lg-11 col-md-11 col-sm-12 col-12 justify-content-center">
                          <button class="btn btn-primary btn-clear">CLEAR ALL</button>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </section>
</form>
