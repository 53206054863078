import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore'
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  constructor(public db: AngularFirestore) {

  }


  addTyres(data: any): Promise<void> {
    const uid = this.generateUUID();
    const date = Date.now();
    return this.db.doc(`tyres/${uid}`).set({ ...data, id: uid, date: date })
  }

  addtoExcel(data: any): Promise<void> {
    const uid = this.generateUUID();
    const date = Date.now();
    return this.db.doc(`excel/${uid}`).set({ ...data, id: uid, date: date })
  }
  getAllExcelData(): AngularFirestoreCollection<any[]> {
    return this.db.collection('excel', ref => ref.orderBy('date', 'desc'));
  }
  getAllTyres(): AngularFirestoreCollection<any[]> {
    return this.db.collection('tyres', ref => ref.orderBy('date', 'desc'));
  }

  addServiceDetails(data: any): Promise<void> {
    const uid = this.generateUUID();
    const date = Date.now();
    return this.db.doc(`services/${uid}`).set({ ...data, id: uid, date: date })
  }

  getAllVisitors(): AngularFirestoreCollection<any[]> {
    return this.db.collection('services', ref => ref.orderBy('date', 'desc'));
  }
  serachVisitors(name: any): AngularFirestoreCollection<any> {
    return this.db.collection(`services`, ref => ref.where('customer_name', '==', name));
  }
  serachVisitorsbyPhone(phone: any): AngularFirestoreCollection<any> {
    return this.db.collection(`services`, ref => ref.where('phone_no', '==', phone));
  }
  getCustomerDetails(id: any): AngularFirestoreCollection<any[]> {
    return this.db.collection(`services`, ref => ref.where('id', '==', id));
  }
  deleteData(id: any): Promise<void> {
    return this.db.doc(`excel/${id}`).delete();
  }
  getExcelDataById(id: any): AngularFirestoreCollection<any[]> {
    return this.db.collection(`excel`, ref => ref.where('id', '==', id));
  }

  updateExcel(id: any, data: any) {
    return this.db.doc(`excel/${id}`).update({ ...data })

  }

  private generateUUID(): any {
    var d = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx'.replace(/[xy]/g, function (c) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
  }


  selectDetailsFromCarNo(carNo): AngularFirestoreCollection<any[]> {
    return this.db.collection(`services`, ref => ref.where('reg_no', '==', carNo).orderBy('date', 'desc'));

  }

  addNotes(data: any): Promise<void> {
    const uid = this.generateUUID();
    const dates = Date.now();
    return this.db.doc(`notes/${uid}`).set({ ...data, id: uid, created: dates })
  }

  getNotes(): AngularFirestoreCollection<any[]> {
    const currentTime = new Date().getTime();
    return this.db.collection(`notes`, ref => ref.where('dateTime', '>=', currentTime).orderBy('dateTime', 'asc'));
  }

  deleteNote(id: string): Promise<void> {
    return this.db.doc(`notes/${id}`).delete();

  }


  addServices(data: any): Promise<void> {
    const uid = this.generateUUID();
    const dates = Date.now();
    return this.db.doc(`ONservices/${uid}`).set({ ...data, id: uid, created: dates })
  }

  getServices(): AngularFirestoreCollection<any[]> {
    const currentTime = new Date().getTime();
    return this.db.collection(`ONservices`, ref => ref.orderBy('dateTime', 'asc'));
  }

  deleteServices(id: string): Promise<void> {
    return this.db.doc(`ONservices/${id}`).delete();

  }

  filterFromandTo(from:any, to:any): AngularFirestoreCollection<any[]> {
    return this.db.collection('services', res => res.where('date', '>=', from).where('date', '<=', to).orderBy('date', 'desc'));
  }
  filterFrom(from:any): AngularFirestoreCollection<any[]> {
    return this.db.collection('services', res => res.where('date', '>=', from).orderBy('date', 'desc'));
  }
  filterTo(to:any): AngularFirestoreCollection<any[]> {
    return this.db.collection('services', res => res.where('date', '<=', to).orderBy('date', 'desc'));
  }

  getTheNoteFromId(id:string): AngularFirestoreDocument<any>{
   return this.db.collection('notes').doc(id);
  }

  getTheHomeFromId(id:string): AngularFirestoreDocument<any>{
    return this.db.collection('services').doc(id);
   }


   filterFromandToUpcoming(from:any, to:any): AngularFirestoreCollection<any[]> {
    return this.db.collection('services', res => res.where('date', '>=', from).where('date', '<=', to).orderBy('date', 'desc'));
  }
  filterFromUpcoming(from:any): AngularFirestoreCollection<any[]> {
    return this.db.collection('services', res => res.where('date', '>=', from).orderBy('date', 'desc'));
  }
  filterToUpcoming(to:any): AngularFirestoreCollection<any[]> {
    return this.db.collection('services', res => res.where('date', '<=', to).orderBy('date', 'desc'));
  }
  loadOthers(): AngularFirestoreCollection<any[]>{
    const todate = new Date().getTime();
    return this.db.collection('services', res => res.where('netVisitTime', '<=', todate).orderBy('netVisitTime', 'desc'));
  }


}
