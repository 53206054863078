import { FormBuilder, FormGroup } from '@angular/forms';
import { FirebaseService } from './../../services/firebase.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-upcoming',
  templateUrl: './upcoming.component.html',
  styleUrls: ['./upcoming.component.css']
})
export class UpcomingComponent implements OnInit {

  public upcomingtForm: FormGroup;
  tableData: any[] = [];

  constructor(public db:FirebaseService, public fb:FormBuilder ) {
    this.upcomingtForm = this.fb.group({
      fromDate: [''],
      toDate:['']
    });
  }

  ngOnInit(): void {
    this.loadData();
  }
  search2(data:any){
    // console.log
    if(data.fromDate.value!='' && data.toDate.value!=''){
      const fromDate  = new Date(data.fromDate.value+"T00:00:01").getTime();
      const toDate  = new Date(data.toDate.value+"T23:59:59").getTime();
      this.db.filterFromandTo(fromDate,toDate).valueChanges().subscribe(res=>this.tableData = res);
    }else if(data.fromDate.value!='' && data.toDate.value==''){
      const fromDate  = new Date(data.fromDate.value+"T00:00:01").getTime();
      this.db.filterFrom(fromDate).valueChanges().subscribe(res=>this.tableData = res);
    }else if(data.fromDate.value=='' && data.toDate.value!=''){
      const toDate  = new Date(data.toDate.value+"T23:59:59").getTime();
      this.db.filterTo(toDate).valueChanges().subscribe(res=>this.tableData = res);
    }else{
      this.loadData();
    }

  }

  loadData(){
    this.db.loadOthers().valueChanges().subscribe((data:any) => {
      this.tableData = data

    });
  }
}
