import { ServicesComponent } from './feature/services/services.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './feature/home/home.component';
import { AddTyresComponent } from './feature/add-tyres/add-tyres.component';
import { AdminLayoutComponent } from './feature/admin-layout/admin-layout.component';
import { LoginComponent } from './feature/login/login.component';
import {LoginGuard} from './guards/login/login.guard';
import { VisitorsComponent } from './feature/visitors/visitors.component';
import { ExcelComponent } from './feature/excel/excel.component';
import { AngularFireAuthGuard, isNotAnonymous } from '@angular/fire/auth-guard';
import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import { NotesComponent } from './feature/notes/notes.component';
import { AddNotesComponent } from './feature/add-notes/add-notes.component';
import { HomeViewComponent } from './feature/home-view/home-view.component';
import { UpcomingComponent } from './feature/upcoming/upcoming.component';
import { PaymentsComponent } from './feature/payments/payments.component';


export const redirectAnonymousTo = (redirect: any[]) =>
  pipe(isNotAnonymous, map (loggedIn => loggedIn || redirect)
);

const redirectUnauthorizedToLogin = () => redirectAnonymousTo(['login']);
const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },

  {
    path: 'login',
    component: LoginComponent
  },
  {path: 'admin',
  component: AdminLayoutComponent,
  canActivate: [LoginGuard],
  children: [
    { path: 'home',  component: HomeComponent, },
    { path: 'home-view/:id',  component: HomeViewComponent, },
    { path: 'add-tyres',  component: AddTyresComponent, },
    { path: 'visitors',  component: VisitorsComponent, },
    {path: 'services', component: ServicesComponent},
    { path: 'excel',  component: ExcelComponent},
    {path: 'upcoming', component: UpcomingComponent},
    {path: 'payments', component: PaymentsComponent},
    {path: 'notes',
    children:[
      {path: 'notes-lists', component: NotesComponent},
      {path: 'view/:id', component: AddNotesComponent},
    ]}


  ]},
  {
    path: '**',
    pathMatch: 'full',
    component:LoginComponent
  }



];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
