<!-- <section class="sec-1 gn-bg-1 h-100">
    <div class="row justify-content-center">
        <div class="col-md-2">
            <img src="../../../assets/images/logo.jpg" alt="" style="width: 250px; padding: 20px;">
        </div>
    </div>
    <div class="row justify-content-center align-middle d-flex ">
        <div class="col-md-4 ">
            <form [formGroup]="loginForm">

                <div class="form-outline mb-4">
                    <input type="email" id="form2Example1" class="form-control" formControlName="email"/>
                    <label class="form-label" for="form2Example1">Email address</label>
                </div>


                <div class="form-outline mb-4">
                    <input type="password" id="form2Example2" class="form-control" formControlName="password" />
                    <label class="form-label" for="form2Example2">Password</label>
                </div>




                <button type="button" class="btn btn-primary btn-block mb-4" (click)="login()">Sign in</button>


            </form>

        </div>

    </div>

</section> -->
<!-- <section class="vh-100 gradient-custom">

    <div class="container py-5 h-100">
      <div class="row d-flex justify-content-center align-items-center h-100">
        <div class="col-12 col-md-8 col-lg-6 col-xl-5">
          <div class="card bg-dark text-white" style="border-radius: 1rem;">

<form [formGroup]="loginForm">
            <div class="card-body p-5 text-center">

              <div class="mb-md-5 mt-md-4 pb-5">

                <h2 class="fw-bold mb-2 text-uppercase">Login</h2>
                <p class="text-white-50 mb-5">Please enter your login and password!</p>

                <div class="form-outline form-white mb-4">
                  <input type="email" id="typeEmailX" class="form-control form-control-lg"  formControlName="email"/>
                  <label class="form-label" for="typeEmailX">Email</label>
                </div>

                <div class="form-outline form-white mb-4">
                  <input type="password" id="typePasswordX" class="form-control form-control-lg" formControlName="password" />
                  <label class="form-label" for="typePasswordX">Password</label>
                </div>

                <p class="small mb-5 pb-lg-2"><a class="text-white-50" href="#!">Forgot password?</a></p>

                <button class="btn btn-outline-light btn-lg px-5" (click)="login()">Login</button>



              </div>



            </div>
        </form>

        </div>
        </div>
      </div>
    </div>
  </section> -->



<section class="login-cls">
    <div class="container">
        <div class="row">
            <div class="col-lg-5 col-md-6 col-12 mx-auto">
                <div class="form-tabs">
                    <h3>Login Now</h3>
                    <form [formGroup]="loginForm">
                        <div class="mb-3">
                            <label class="form-label">Email address</label>
                            <input
                                type="email"
                                class="form-control log"
                                name
                                formControlName="email"
                            >
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Password</label>
                            <input
                                type="password"
                                class="form-control log"
                                name
                                formControlName="password"
                            >
                        </div>
                        <button
                            type="submit"
                            class="btn btn-primary mylogbtn"
                            (click)="login()"
                        >
                            Submit
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
