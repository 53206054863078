import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-search-reg-popup',
  templateUrl: './search-reg-popup.component.html',
  styleUrls: ['./search-reg-popup.component.css'],

})
export class SearchRegPopupComponent implements OnInit {

  diologdatas: any;
  selectedData: any[] = [];

  constructor(private dialogRef: MatDialogRef<SearchRegPopupComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public db: FirebaseService) { }

  ngOnInit(): void {
    this.diologdatas = this.data;
    this.db.selectDetailsFromCarNo(this.data.carno).valueChanges().subscribe(datas => {
      console.log(datas);
      this.selectedData = datas
    });

  }

  returnDatas(item: any) {
    this.dialogRef.close(item);
  }

}
