import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;

  constructor(public fb:FormBuilder, private ls: LoginService, public router:Router) { 
    this.loginForm = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });

  }

  ngOnInit(): void {
  }

  login(){
    if(this.loginForm.invalid){
      alert("Please Select enter Username and password");
      this.loginForm.reset();
    }else{
      const email = this.loginForm.value.email;
      const password = this.loginForm.value.password;
      this.ls.login(email,password).then(res=>{
        localStorage.setItem('email',email);
        this.router.navigateByUrl('/admin/home');
        
      });
    }
  }

}
