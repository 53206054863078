<form [formGroup]="paymentForm">

  <section class="sec-1 gn-bg-1">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="row ">
              <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                <div class="P2-hrow-col1">
                  <p class="gn-txt">From:</p>
                  <input type="date" class="text-box gn-txt" formControlName="fromDate">
                </div>
              </div>
  <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                <div class="P2-hrow-col1">
                  <p class="gn-txt">To:</p>
                  <input type="date" class="text-box gn-txt" formControlName="toDate">
                </div>
              </div>

              <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                <button class="btn btn-primary btn-dsn" (click)="search2(this.paymentForm.controls);" >Search</button>&nbsp;
              </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  </form>

<section class="sec-pagesix gn-bg-2">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <div class="row">
          <div class="col-lg-10 col-md-10 col-sm-12 col-12 mx-auto">
            <div class="pagesix-tb">
              <table class="table table-bordered">
                  <thead>
                    <tr>
                        <td>JobCard No</td>
                        <td>V Number</td>
                        <td>Model</td>
                        <td>Customer Name</td>
                        <td>Total Payments</td>
                        <td>Payments Methods</td>
                        <td>View</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of tableData; let i = index">
                        <td>{{item.job_card_no}}</td>
                        <td>{{item.reg_no}}</td>
                        <td>{{item.model}}</td>
                        <td>{{item.customer_name}}</td>
                        <td>{{item.itemTotal + item.serviceTotalTotalAMount}}</td>
                        <td>{{item.paymentMode}}</td>
                        <td><button class="btn btn-primary" routerLink="/admin/home-view/{{item.id}}"><i class="fa fa-eye" aria-hidden="true"></i></button></td>
                    </tr>

                  </tbody>
              </table>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</section>
