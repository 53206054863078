import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FirebaseService } from '../../services/firebase.service';

@Component({
  selector: 'app-add-notes',
  templateUrl: './add-notes.component.html',
  styleUrls: ['./add-notes.component.css']
})
export class AddNotesComponent implements OnInit {
public id: string;
  tableData: any;
  constructor(private route: ActivatedRoute, private db:FirebaseService) {
  this.id = this.route.snapshot.params.id;
  this.db.getTheNoteFromId(this.id).valueChanges().subscribe(res=>this.tableData = res);
  }

  ngOnInit(): void {
  }

}
