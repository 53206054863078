<h2 mat-dialog-title>Services</h2>
<mat-dialog-content class="mat-typography">
  <section>
    <table border="1" class="table table-bordered">
      <thead>
        <tr>
          <th>No</th>
          <th>Service Name</th>
          <th>Service Code</th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let item of tableData; let i  = index" (click)="returnDatas(item)">
          <td>{{i+1}}</td>
          <td>{{item.name}}</td>
          <td>{{item.code}}</td>
        </tr>
      </tbody>

    </table>

  </section>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>


