import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './feature/home/home.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';
import { AddTyresComponent } from './feature/add-tyres/add-tyres.component';
import { AdminLayoutComponent } from './feature/admin-layout/admin-layout.component';
import { LoginComponent } from './feature/login/login.component';
import { ExcelComponent } from './feature/excel/excel.component';
import { VisitorsComponent } from './feature/visitors/visitors.component';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { NotesComponent } from './feature/notes/notes.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatDialogModule} from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { SearchRegPopupComponent } from './feature/search-reg-popup/search-reg-popup.component';
import { AddNotesComponent } from './feature/add-notes/add-notes.component';
import {MatInputModule} from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { TyresComponent } from './feature/popups/tyres/tyres.component';
import { WheelsComponent } from './feature/popups/wheels/wheels.component';
import { ServicesComponent } from './feature/services/services.component';
import { HomeViewComponent } from './feature/home-view/home-view.component';
import { UpcomingComponent } from './feature/upcoming/upcoming.component';
import { PaymentsComponent } from './feature/payments/payments.component';





@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AddTyresComponent,
    AdminLayoutComponent,
    LoginComponent,
    ExcelComponent,
    VisitorsComponent,
    NotesComponent,
    SearchRegPopupComponent,
    AddNotesComponent,
    TyresComponent,
    WheelsComponent,
    ServicesComponent,
    HomeViewComponent,
    UpcomingComponent,
    PaymentsComponent,

  ],
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    BrowserModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatInputModule,
    MatDialogModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFireAuthModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
