import { FirebaseService } from './../../services/firebase.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {

  servicesForm: FormGroup;
  tableData: any[]=[];
  constructor(public fb:FormBuilder, public db:FirebaseService) {
    this.db.getServices().valueChanges().subscribe(res=>{
      this.tableData = res;
    });
   }

  ngOnInit(): void {
    this.servicesForm = this.fb.group({
      shortCode: ['', Validators.required],
      serviceName: ['', Validators.required]
    });


  }

  formSubmit(){
    if(this.servicesForm.valid){
      const code = this.servicesForm.value.shortCode;
      const name = this.servicesForm.value.serviceName;
      const date = Date.now();

      const data = {
        code: code,
        name: name,
        dateTime: date,
      }
      this.db.addServices(data).then(res => alert("Services Added Successfully"), error => alert("please try another time"));
    }else{
      alert("Please Fill the form");
    }
  }


  deleteServices(id:string){
    this.db.deleteServices(id).then(res => alert("Note Deleted Successfully"), error => alert("please try another time"));
  }


}
