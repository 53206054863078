<section class="sec-tyre gn-bg-1">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="form-four">


                    <form [formGroup]="notesForm">
                        <h3>Reminder</h3>
                        <div class="Pfour-hrow-col1">
                            <p class="gn-txt">Job Card Number:</p>
                            <input
                                type="text"
                                class="text-boxfour gn-txt"
                                formControlName="title"
                            >
                        </div>
                        <div class="Pfour-hrow-col1">
                            <p class="gn-txt">Reminder Date:</p>
                            <input
                                type="date"
                                class="text-boxfour gn-txt"
                                formControlName="date"
                            >
                        </div>
                        <div class="Pfour-hrow-col1">
                            <p class="gn-txt">Message:</p>
                            <textarea
                                type="text"
                                class="text-boxfourt gn-txt"
                                formControlName="note"
                            ></textarea>
                        </div>
                        <div class="Pfour-hrow-col1">
                            <button class="btn btn-primary" (click)="formSubmit()">ADD</button>
                        </div>
                    </form>


                </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="row">
                    <div class="col-lg-5 col-md-5 col-sm-12 col-12">
                        <div class="pagefive-tb">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <td>SINo</td>
                                        <td>JobCard Number</td>
                                        <td>Reminder Date</td>
                                        <td>Message</td>
                                        <td>Delete</td>
                                        <td>Read</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of tableData; let i = index">
                                        <td>{{i+1}}</td>
                                        <td>{{item.title}}</td>
                                        <td>{{item.dateTime}}</td>
                                        <td>{{item.note | slice:0:24}}...</td>
                                        <td>
                                            <button class="btn btn-primary" (click)="deleteNote(item.id)">
                                                <i
                                                    class="fa fa-trash-o"
                                                    aria-hidden="true"
                                                ></i>
                                            </button>
                                        </td>
                                        <td>
                                            <a class="btn btn-primary" [routerLink]="'/admin/notes/view/'+item.id">
                                                <i
                                                    class="fa fa-pencil-square-o"
                                                    aria-hidden="true"
                                                ></i>
                                            </a>
                                        </td>
                                    </tr>


                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    </div>
</section>
