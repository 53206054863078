import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.css']
})
export class AdminLayoutComponent implements OnInit {

  constructor(public af:AngularFireAuth, public rout:Router) { }

  ngOnInit(): void {
  }

  logout(){
    this.af.signOut().then(
      ()=>{
        this.rout.navigateByUrl('login');
        localStorage.removeItem('email');
      }
    )
  }

}
