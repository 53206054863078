import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FirebaseService } from './../../../services/firebase.service';
import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'app-wheels',
  templateUrl: './wheels.component.html',
  styleUrls: ['./wheels.component.css']
})
export class WheelsComponent implements OnInit {
  tableData: any[]=[];
  indexes: any;
  constructor(public db: FirebaseService, private dialogRef: MatDialogRef<WheelsComponent>, @Inject(MAT_DIALOG_DATA) public data: any,) {
    this.indexes = this.data;
   }

  ngOnInit(): void {
    console.log(this.indexes.index);
    this.db.getServices().valueChanges().subscribe(data=>{
      this.tableData = data;
    });
  }

  returnDatas(item){
    const data = {
      ...item,
      index: this.indexes.index,
    }
    this.dialogRef.close(data);
  }

}
