import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FirebaseService } from 'src/app/services/firebase.service';
import * as XLSX from 'xlsx';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-visitors',
  templateUrl: './visitors.component.html',
  styleUrls: ['./visitors.component.css']
})
export class VisitorsComponent implements OnInit {
  searchVisitorsForm:FormGroup
  formSubmited: boolean;
  tableData: any[];
  excelTableData: any[];
  constructor( private formBuilder: FormBuilder,private db:FirebaseService,private router:Router, @Inject(LOCALE_ID) public locale: string) { }

  ngOnInit(): void {
    this.initilizeForms();
    this.loadData();
  }

  initilizeForms(){
    this.searchVisitorsForm = this.formBuilder.group({
      // from_date:[''],
      // to_date:[''],
      name:[''],
      phone:['']
    })

  }
  search2(data:any){
    // console.log
    if(data.name.value!='' && data.phone.value!=''){
      const fromDate  = new Date(data.name.value+"T00:00:01").getTime();
      const toDate  = new Date(data.phone.value+"T23:59:59").getTime();
      this.db.filterFromandTo(fromDate,toDate).valueChanges().subscribe(res=>this.tableData = res);
    }else if(data.name.value!='' && data.phone.value==''){
      const fromDate  = new Date(data.name.value+"T00:00:01").getTime();
      this.db.filterFrom(fromDate).valueChanges().subscribe(res=>this.tableData = res);
    }else if(data.name.value=='' && data.phone.value!=''){
      const toDate  = new Date(data.phone.value+"T23:59:59").getTime();
      this.db.filterTo(toDate).valueChanges().subscribe(res=>this.tableData = res);
    }else{
      this.loadData();
    }

  }
  search(data:any){
    console.log(data.name.value)
    if(data.name?.value ){
    this.db.serachVisitors(data.name.value).valueChanges().subscribe((res:any)=>{
      this.tableData = res;
    })
  }
    else if(data.phone?.value){
      console.log(data.phone?.value)
        this.db.serachVisitorsbyPhone(data.phone.value).valueChanges().subscribe((res:any)=>{
          this.tableData = res;
        })
    }
    else{
      this.loadData()
    }

  }

  clear(){
    this.searchVisitorsForm.reset();
    this.loadData()

  }

  loadData(){
    this.db.getAllVisitors().valueChanges().subscribe((data:any) => {
      this.tableData = data

    });
  }

  vieHistory(id:any){
    this.router.navigateByUrl('/admin/home/'+id)
  }

exportToExcel(){
  this.excelTableData = [];
  this.tableData.forEach(data=>{
    this.excelTableData.push({
      'Customer Name': data.customer_name,
      'Customer Phone': data.customer_name,
      'Model': data.model,
      'Reg No': data.reg_no,
      'Date': formatDate(data.date, 'dd-MM-yyyy',this.locale)
    });
  });

  this.downloadExcel(this.excelTableData,'UserLists');

}

  downloadExcel(downloadData: any, fileName: any) {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(downloadData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.sheet_add_json(ws, downloadData, {
      origin: 'A2',
      skipHeader: true,
    });
    XLSX.utils.book_append_sheet(wb, ws, 'Export Advance List');
    XLSX.writeFile(wb, fileName + '.xlsx');
  }

  printPage() {
    const printContents = document.getElementById('section-to-print');
    const WindowPrt = window.open('', '', 'left=0,top=0,width=900,height=900,toolbar=yes,scrollbars=0,status=0');
    WindowPrt.document.write(printContents.innerHTML);
    WindowPrt.document.close();
    WindowPrt.focus();
    WindowPrt.print();
    WindowPrt.close();
  }

}
